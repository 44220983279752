<ng-container *transloco="let t">
  <cft-content-section
    [sectionId]="reviewSection.id"
    titleAlignment="left"
    [plot]="reviewSection.plot"
    [title]="reviewSection.title"
    [description]="reviewSection.description"
    (secondaryCallToActionClick)="buttonClicked.emit()"
    [secondaryCallToAction]="reviewSection.callToActionTitle"
  >
    <cft-rating [rating]="t('siteRating.rating')" class="text-base"></cft-rating>

    <cft-slider
      [alignCenter]="false"
      class="mt-xl pb-md"
      [backButtonTitle]="t('slider.back')"
      [forwardButtonTitle]="t('slider.forward')"
    >
      @for (review of reviewSection.reviews; track review) {
        <bgo-review-card
          [text]="review.comment"
          [author]="review.author"
          [platform]="review.platform"
          [rating]="review.rating"
        ></bgo-review-card>
      }
    </cft-slider>
  </cft-content-section>
</ng-container>
